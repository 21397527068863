import React from "react";
import CallActions from "./CallToActions";

export default function BehaviouralDiagnosis() {
    return (
        <div>
            <div
                className="fables-header"
                style={{ backgroundImage: "url(assets/custom/images/header.jpg)" }}
            >
                <div className="fables-after-overl">
                    <div className="container">
                        <div className="text-center py-7 text-white animate__animated animate__zoomIn">
                            <h2 className=" wow fadeInLeft" data-wow-duration="1.5s">
                                Behavioural Diagnosis
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-3">
                <h2 className="font-30 font-weight-bold fables-second-text-color mt-4 text-center">
                    Unlock Your Team's Potential
                </h2>
                <p>At <strong> BCD Services</strong>, we recognize the importance of understanding human behaviour in driving personal and organizational success. Our Behavioural Diagnosis Solutions offer a comprehensive assessment of individual and group behaviours, providing actionable insights to foster growth and development.</p>
                <h3 className="fables-second-text-color">Why Choose Behavioural Diagnosis Services?</h3>
                <ul style={{ listStyle: "initial" }}>
                    <li> <strong>Enhanced Team Dynamics: </strong> Understand the interpersonal dynamics at play within your team to foster collaboration and cohesion</li>
                    <li><strong>Improved Communication: </strong> Identify communication barriers and develop strategies to enhance communication effectiveness across the organization</li>
                    <li><strong>Conflict Resolution: </strong> Address underlying conflicts and promote a culture of constructive dialogue and conflict resolution</li>
                    <li><strong>Increased Productivity: </strong> Boost team performance and productivity by addressing behavioural obstacles that may be hindering progress.</li>
                </ul>

                <div>
                    <h3 className="fables-second-text-color text-center">Our Services</h3>
                    <table className="table table1">
                        <thead>
                            <tr>
                                <th>Services</th>
                                <th>Services Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Behaviour Assessment</th>
                                <td >Gain insights into personality traits, communication styles, and preferences to optimize personal effectiveness</td>
                            </tr>
                            <tr>
                                <th scope="row">Team Dynamics Analysis</th>
                                <td >Assess team dynamics, strengths, and areas for improvement to enhance collaboration and productivity</td>
                            </tr>
                            <tr>
                                <th scope="row">Leadership Behaviour Evaluation</th>
                                <td >Analyse leadership styles, strengths, and potential areas for development to foster effective leadership</td>
                            </tr>
                            <tr>
                                <th scope="row">Conflict Resolution Strategies</th>
                                <td >Develop strategies to address and resolve conflicts within teams and organizations, promoting a positive work environment</td>
                            </tr>
                            <tr>
                                <th scope="row">Culture Assessment</th>
                                <td >Evaluate organizational culture and align behaviours with organizational values and objectives for greater alignment and success</td>
                            </tr>
                            <tr>
                                <th scope="row">Customized Assessment</th>
                                <td >Tailored solutions to address specific behavioural challenges and opportunities within your organization</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h3 className="fables-second-text-color">Why Choose Us?</h3>
                <ul style={{ listStyle: "initial" }}>
                    <li> <strong>Expertise: </strong> Our team of certified behavioural analysts brings extensive experience in understanding and analysing human behaviour</li>
                    <li><strong>Customization: </strong> We tailor our diagnosis approach to fit the unique needs and goals of individuals and organizations</li>
                    <li><strong>Data-Driven Insights:  </strong> Utilizing advanced behavioural assessment tools, we uncover valuable insights to inform decision-making and drive positive change</li>
                    <li><strong>Empowerment: </strong> We empower individuals and teams with the knowledge and tools to enhance communication, collaboration, and performance</li>
                </ul>

                <p><strong>Ready to Understand Behaviour and Drive Growth? </strong> Contact us today to schedule a consultation and take the first step towards unlocking insights for personal and organizational growth</p>
               
            </div>
            <CallActions/>
        </div>
    )
}